function getCanvasImageData (imageBuffer) {
  const canvasImage = new window.Image()
  canvasImage.crossOrigin = 'Anonymous'

  return new Promise((resolve, reject) => {
    canvasImage.onerror = function () {
      reject(new Error('error while reading from input stream'))
    }

    canvasImage.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = canvasImage.width
      canvas.height = canvasImage.height

      const context = canvas.getContext('2d')
      context.drawImage(canvasImage, 0, 0)

      const data = context.getImageData(0, 0, canvasImage.width, canvasImage.height).data
      resolve(data)
    }
    canvasImage.src = imageBuffer
  })
}

export default async function getPixels (img) {
  const data = await getCanvasImageData(img)
  let whitePixels = 0

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const avg = (r + g + b) / 3

    if (avg > 127) {
      whitePixels += 1
    }
  }

  return { pixels: data.length / 4, whitePixels }
}
