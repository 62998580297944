import preact, { render, Component } from 'preact'
import styles from './index.css'
import dragDrop from 'drag-drop'
import analyze from './histogram'

/** @jsx preact.h */

function getBuffer(file) {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', function(e) {
      // e.target.result is an ArrayBuffer
      resolve(e.target.result)
    })
    reader.addEventListener('error', reject)
    reader.readAsDataURL(file)
  })
}

function isImage(file) {
  return (
    file.isFile &&
    (file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.name.endsWith('.jpg') ||
      file.name.endsWith('.jpeg') ||
      file.name.endsWith('.png'))
  )
}

class Dropzone extends Component {
  constructor() {
    super()
    this.state = { files: [], results: {} }
  }

  componentDidMount() {
    dragDrop(this.dropzone, files => {
      const images = files.filter(isImage)

      this.setState({ files: images })

      images.forEach(async file => {
        try {
          const label = `processing ${file.name}`

          console.time(label)
          const buf = await getBuffer(file)
          const result = await analyze(buf)
          console.timeEnd(label)

          this.setState(state => ({
            results: Object.assign(state.results, { [file.name]: result })
          }))
        } catch (error) {
          console.error(error)
        }
      })
    })
  }

  render() {
    const { files, results } = this.state

    return (
      <div
        ref={el => {
          this.dropzone = el
        }}
        className={styles.dropzone}
      >
        {!files.length && (
          <div className={styles.message}>
            Dateien oder Ordner hier ablegen. Es können .jpg und .png Dateien verarbeitet werden.
          </div>
        )}

        {files.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th className={styles.number}>Pixel</th>
                <th className={styles.number}>Weiße Pixel</th>
                <th className={styles.number}>%</th>
              </tr>
            </thead>
            <tbody />
            {files.map(file => {
              const result = results[file.name]

              return (
                <tr key={file.name}>
                  <td>{file.name}</td>
                  {result && <td className={styles.number}>{result.pixels}</td>}
                  {result && <td className={styles.number}>{result.whitePixels}</td>}
                  {result && (
                    <td className={styles.number}>
                      {Math.round((result.whitePixels / result.pixels) * 1000) / 10}%
                    </td>
                  )}
                  {!result && (
                    <td className={styles.number} colSpan={3}>
                      <div className={styles.spinner} />
                    </td>
                  )}
                </tr>
              )
            })}
          </table>
        )}
      </div>
    )
  }
}

render(<Dropzone />, document.body)
